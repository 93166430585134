import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const SEOptimizedBlog = () => (
  <Layout>
    <SEO title="SEOptimized Blog " />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Additional SEO Technical Support Hours | $175 per hour</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <p>
              Search Engine Optimization (SEO) Technical Support is vital for
              any business that wants to compete in the digital marketplace. SEO
              tech support helps businesses optimize their websites for search
              engines, allowing them to reach more potential customers.<br></br>
              Technical Support helps businesses identify potential issues, such
              as slow loading times, broken links, and incorrect metadata, that
              can negatively affect their SEO rankings. Technical Support also
              helps businesses implement the latest best practices to ensure
              that their content is as optimized as possible.<br></br>
              Ultimately, SEO Technical Support is essential for any business
              that wants to succeed in the digital world and ad-hoc hours are a
              great way to get help on an as needed or specific case basis.
            </p>
            <p></p>

            <p className="text-red">
              This can only be purchased as an add-on to one of our other SEO
              packages. Please return to the SEO packages page and select this
              add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default SEOptimizedBlog;
